import * as React from "react";
import Paper from "@mui/material/Paper";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
	Scheduler,
	WeekView,
	Appointments,
	Toolbar,
	ViewSwitcher,
	MonthView,
	DayView,
	DateNavigator,
	AppointmentTooltip,
	Resources,
} from "@devexpress/dx-react-scheduler-material-ui";
import { useState } from "react";
import moment from "moment";
import { useEffect } from "react";
import {
	Box,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

function TicketAppointmentCalendar(props) {
	const data = props.appointments;
	const [currentViewName, setCurrentViewName] = useState();
	const [formattedData, setFormattedData] = useState([]);
	const [value, setValue] = useState("1");
	const [firstCampList, setFirstCampList] = useState([]);
	const [secondCampList, setSecondCampList] = useState([]);
	const [thirdCampList, setThirdCampList] = useState([]);
	const [fourthCampList, setFourthCampList] = useState([]);
	const mainResourceName = "type";
	const resources = [
		{
			fieldName: "type",
			title: "type",
			instances: [
				{ id: "oneTimeTicket", text: "Egy alkalmas belépő" },
				{ id: "pass", text: "Bérlet" },
				{ id: "birthday", text: "Szülinapi helyszín" },
			],
		},
	];
	useEffect(() => {
		formatDataForCalendar(data);
	}, [data]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const formatDataForCalendar = (data) => {
		if (data.length > 0) {
			let tmpFormmattedData = [];
			let tmpFirstCampList = [];
			let tmpSecondCampList = [];
			let tmpThirdCampList = [];
			let tmpFourthCampList = [];
			data.forEach((element) => {
				let data = {
					id: element.id,
					title: element.childrenName,
					startDate: new Date(moment(element.startDate).format("YYYY-M-D H:m")),
					endDate: new Date(moment(element.endDate).format("YYYY-M-D H:m")),
					...element,
				};
				tmpFormmattedData.push(data);
				if (element.type === "camp") {
					if (element.turn === "agust_14_18") {
						tmpFirstCampList.push(data);
					} else if (element.turn === "agust_21_25") {
						tmpSecondCampList.push(data);
					} else if (element.turn === "agust_28_sept_1") {
						tmpThirdCampList.push(data);
					} else if (element.turn === "sept_4_sept_8") {
						tmpFourthCampList.push(data);
					}
				}
			});

			setFormattedData(tmpFormmattedData);
			setFirstCampList(tmpFirstCampList);
			setSecondCampList(tmpSecondCampList);
			setThirdCampList(tmpThirdCampList);
			setFourthCampList(tmpFourthCampList);
		}
	};

	const currentViewNameChange = (currentView) => {
		setCurrentViewName(currentView);
	};

	const Content = ({ children, appointmentData, classes, ...restProps }) => (
		<AppointmentTooltip.Content
			{...restProps}
			appointmentData={appointmentData}
		>
			<Grid container alignItems="center">
				<Grid item xs={12}>
					{appointmentData.type === "pass" ||
					appointmentData.type === "oneTimeTicket" ? (
						<ul>
							<li>Szülő neve : {appointmentData.parentName}</li>
							<li>Telefonszám : {appointmentData.phone}</li>
							<li>E-mail cím: {appointmentData.email}</li>
							<li>Megjegyzések: {appointmentData.description}</li>
						</ul>
					) : (
						<ul>
							<li>Szülő neve : {appointmentData.parentName}</li>
							<li>Telefonszám : {appointmentData.phone}</li>
							<li>E-mail cím: {appointmentData.email}</li>
							<li>Gyerekek száma: {appointmentData.childrenNumber}</li>
							<li>Felnőttek száma: {appointmentData.parentsNumber}</li>
							<li>
								Születésnap dátuma:{" "}
								{moment(appointmentData.childrenBirthDate).format("YYYY-MM-DD")}
							</li>
							<li>Megjegyzések: {appointmentData.description}</li>
						</ul>
					)}
				</Grid>
			</Grid>
		</AppointmentTooltip.Content>
	);
	return (
		<div>
			<Paper>
				<Scheduler data={formattedData} height={660} locale={"hu-HU"}>
					<ViewState
						defaultCurrentDate={moment()}
						currentViewName={currentViewName}
						onCurrentViewNameChange={currentViewNameChange}
					/>
					<WeekView displayName="Hét" />
					<MonthView displayName="Hónap" />
					<DayView displayName="Nap" />
					<Toolbar />
					<DateNavigator />
					<ViewSwitcher />
					<Appointments />
					<AppointmentTooltip contentComponent={Content} />
					<Resources data={resources} mainResourceName={mainResourceName} />
				</Scheduler>
			</Paper>
			<TabContext value={value}>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<TabList onChange={handleChange} aria-label="lab API tabs example">
						<Tab label="Agusztus 14. -Agusztus 18." value="1" />
						<Tab label="Agusztus 21. - Agusztus 25." value="2" />
						<Tab label="Agusztus 28.- September 1." value="3" />
						<Tab label="Szeptember 4. - Szeptember 8. " value="4" />
					</TabList>
				</Box>
				<TabPanel value="1">
					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 650 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>#</TableCell>
									<TableCell>Gyerek teljes neve</TableCell>
									<TableCell>Szülő teljes neve</TableCell>
									<TableCell>Gyerek életkora</TableCell>
									<TableCell>Fizetési mód</TableCell>
									<TableCell>Időszak</TableCell>
									<TableCell>Email</TableCell>
									<TableCell>Telefonszám</TableCell>
									<TableCell>Megjegyzés</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{firstCampList.map((row, index) => {
									if (row.type === "camp" && row.turn === "agust_14_18") {
										return (
											<TableRow
												key={row.name}
												sx={{
													"&:last-child td, &:last-child th": { border: 0 },
												}}
											>
												<TableCell>{index + 1}</TableCell>
												<TableCell>{row.childrenName}</TableCell>
												<TableCell>{row.parentName}</TableCell>
												<TableCell>{row.childrenAge}</TableCell>
												<TableCell>{row.paymentMethod}</TableCell>
												<TableCell>{row.turn}</TableCell>
												<TableCell>{row.email}</TableCell>
												<TableCell>{row.phone}</TableCell>
												<TableCell>{row.description}</TableCell>
											</TableRow>
										);
									}
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</TabPanel>
				<TabPanel value="2">
					{" "}
					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 650 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>#</TableCell>
									<TableCell>Gyerek teljes neve</TableCell>
									<TableCell>Szülő teljes neve</TableCell>
									<TableCell>Gyerek életkora</TableCell>
									<TableCell>Fizetési mód</TableCell>
									<TableCell>Időszak</TableCell>
									<TableCell>Email</TableCell>
									<TableCell>Telefonszám</TableCell>
									<TableCell>Megjegyzés</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{secondCampList.map((row, index) => {
									if (row.type === "camp" && row.turn === "agust_21_25") {
										return (
											<TableRow
												key={row.name}
												sx={{
													"&:last-child td, &:last-child th": { border: 0 },
												}}
											>
												<TableCell>{index + 1}</TableCell>
												<TableCell>{row.childrenName}</TableCell>
												<TableCell>{row.parentName}</TableCell>
												<TableCell>{row.childrenAge}</TableCell>
												<TableCell>{row.paymentMethod}</TableCell>
												<TableCell>{row.turn}</TableCell>
												<TableCell>{row.email}</TableCell>
												<TableCell>{row.phone}</TableCell>
												<TableCell>{row.description}</TableCell>
											</TableRow>
										);
									}
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</TabPanel>

				<TabPanel value="3">
					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 650 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>#</TableCell>
									<TableCell>Gyerek teljes neve</TableCell>
									<TableCell>Szülő teljes neve</TableCell>
									<TableCell>Gyerek életkora</TableCell>
									<TableCell>Fizetési mód</TableCell>
									<TableCell>Időszak</TableCell>
									<TableCell>Email</TableCell>
									<TableCell>Telefonszám</TableCell>
									<TableCell>Megjegyzés</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{thirdCampList.map((row, index) => {
									if (row.type === "camp" && row.turn === "agust_28_sept_1") {
										return (
											<TableRow
												key={row.name}
												sx={{
													"&:last-child td, &:last-child th": { border: 0 },
												}}
											>
												<TableCell>{index + 1}</TableCell>
												<TableCell>{row.childrenName}</TableCell>
												<TableCell>{row.parentName}</TableCell>
												<TableCell>{row.childrenAge}</TableCell>
												<TableCell>{row.paymentMethod}</TableCell>
												<TableCell>{row.turn}</TableCell>
												<TableCell>{row.email}</TableCell>
												<TableCell>{row.phone}</TableCell>
												<TableCell>{row.description}</TableCell>
											</TableRow>
										);
									}
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</TabPanel>

				<TabPanel value="4">
					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 650 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>#</TableCell>
									<TableCell>Gyerek teljes neve</TableCell>
									<TableCell>Szülő teljes neve</TableCell>
									<TableCell>Gyerek életkora</TableCell>
									<TableCell>Fizetési mód</TableCell>
									<TableCell>Időszak</TableCell>
									<TableCell>Email</TableCell>
									<TableCell>Telefonszám</TableCell>
									<TableCell>Megjegyzés</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{fourthCampList.map((row, index) => {
									if (row.type === "camp" && row.turn === "sept_4_sept_8") {
										return (
											<TableRow
												key={row.name}
												sx={{
													"&:last-child td, &:last-child th": { border: 0 },
												}}
											>
												<TableCell>{index + 1}</TableCell>
												<TableCell>{row.childrenName}</TableCell>
												<TableCell>{row.parentName}</TableCell>
												<TableCell>{row.childrenAge}</TableCell>
												<TableCell>{row.paymentMethod}</TableCell>
												<TableCell>{row.turn}</TableCell>
												<TableCell>{row.email}</TableCell>
												<TableCell>{row.phone}</TableCell>
												<TableCell>{row.description}</TableCell>
											</TableRow>
										);
									}
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</TabPanel>
			</TabContext>
		</div>
	);
}

export default TicketAppointmentCalendar;
