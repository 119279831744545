import { ThemeProvider } from "@mui/styles";
import theme from "../UI/Theme";
import Footer from "./Footer";
import HomePage from "./HomePage";
import Newsletter from "./Newsletter";
import ProductCategories from "./ProductCategories";
import ProductHowItWorks from "./ProductHowItWorks";
import ProductValues from "./ProductValues";
import MainProductCategories from "./MainProductCategories";
import { AppBar, Box, Grid, Toolbar, Typography } from "@mui/material";
import one from "../Assets/images/1.png";
import two from "../Assets/images/2.png";
import three from "../Assets/images/3.png";
import { Helmet } from "react-helmet";
import headerPicture from "../Assets/images/headerPicture.jpg";
function MainPage() {
	return (
		<ThemeProvider theme={theme}>
			<Box sx={{ flexGrow: 1 }}>
				<AppBar color="inherit" position="fixed">
					<Toolbar>
						<Grid
							container
							direction="row"
							justifyContent="space-between"
							alignItems="center"
						>
							<Grid item textAlign={"center"} xs={4}>
								<img border="0" src={one} alt="wonder" width="20%" />
							</Grid>
							<Grid item textAlign={"center"} xs={4}>
								<img src={two} alt="wonder" width="20%" />
							</Grid>
							<Grid item textAlign={"center"} xs={4}>
								<img src={three} alt="wonder" width="20%" />
							</Grid>
							<Grid item textAlign={"center"} xs={12}>
								<Typography sx={{ fontStyle: "italic" }}>
									Conținutul acestui material nu reprezintă în mod obligatoriu
									poziția oficială a Uniunii Europene sau a Guvernului României
								</Typography>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				<Toolbar />
			</Box>
			<HomePage />
			<MainProductCategories />
			<ProductValues />
			<ProductCategories />
			<ProductHowItWorks />
			<Newsletter />
			<Footer />
		</ThemeProvider>
	);
}

export default MainPage;
