import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Container,
	Grid,
	Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(4),
	},
}));

function MainProductCategories(props) {
	const classes = useStyles();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const products = [
		{
			url: "https://images.unsplash.com/photo-1606092195730-5d7b9af1efc5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
			title: t("campCardTitle"),
			type: "camp",
			description: t("campCardDecription"),
		},
		{
			url: "https://images.unsplash.com/photo-1607211851821-8be3cd6146f0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
			title: t("oneTimeCardTitle"),
			type: "oneTimeTicket",
			description: t("oneTimeCardDescription"),
		},
		{
			url: "https://images.unsplash.com/photo-1554343594-1c9d305bd51f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
			title: t("passCardTitle"),
			type: "pass",
			description: t("passCardDescription"),
		},
		{
			url: "https://images.unsplash.com/photo-1553710120-23dd1551da41?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
			title: t("birthdayCardTitle"),
			type: "birthday",
			description: t("birthdayCardDescription"),
		},
	];

	const navigateToAppointmentPage = (type) => {
		navigate("/apointment", { state: { type: type } });
	};

	return (
		<Container className={classes.root} component="section">
			<Grid
				container
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={2}
			>
				{products.map((product) => (
					<Grid item xs={12} sm={3}>
						<Card
							sx={{
								minHeight: 550,
								display: "flex",
								flexDirection: "column",
							}}
						>
							<CardMedia
								sx={{ height: 140 }}
								image={product.url}
								title="green iguana"
							/>
							<CardContent>
								<Typography gutterBottom variant="h5" component="div">
									{product.title}
								</Typography>
								<Typography variant="body2" color="text.secondary">
									{product.description}
								</Typography>
							</CardContent>
							<CardActions disableSpacing sx={{ mt: "auto" }}>
								<Grid
									container
									direction="row"
									justifyContent="center"
									alignItems="center"
									spacing={2}
								>
									<Grid item>
										<Button
											disabled={product.type !== "camp"}
											onClick={() => navigateToAppointmentPage(product.type)}
											size="small"
										>
											{t("makeAppointmentButtonText")}
										</Button>
									</Grid>
								</Grid>
							</CardActions>
						</Card>
					</Grid>
				))}
			</Grid>
		</Container>
	);
}

MainProductCategories.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default MainProductCategories;
